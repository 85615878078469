import React from "react"

const alertStyle = {
  backgroundColor: "#E85A4F",
  color: "white",
  padding: "10px",
  textTransform: "uppercase",
  textAlign: "center",
  borderRadius: "3px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 2px 2px 2px rgba(0, 0, 0, 0.03)",
  fontFamily: "Arial",
  width: "350px",
  boxSizing: "border-box",
}

const AlertTemplate = ({ message, options, style, close }) => {
  return (
    <div style={{ ...alertStyle, ...style }}>
      {options.type === "info"}
      {options.type === "success"}
      {options.type === "error"}
      <span style={{ flex: 2 }}>{message}</span>
    </div>
  )
}

export default AlertTemplate
