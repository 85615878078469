/** @jsx jsx */
import React from "react"
import { render } from 'react-dom'
import Layout from "../components/layout"
import SEO from "../components/seo"
import SignupForm from "../components/signup"
import HeartDislike from "../assets/heartdislike.inline.svg"
import Wand from "../assets/wand.inline.svg"
import Cog from "../assets/cog.inline.svg"
import Pulse from "../assets/pulse.inline.svg"
import Mail from "../assets/mail.inline.svg"
import { jsx, Styled, Container } from "theme-ui"
import FadeIn from 'react-fade-in'
import Typing from 'react-typing-animation'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from '../components/alerttemplate'

const options = {
  position: positions.TOP_CENTER,
  timeout: 2000, 
  transitions: transitions.FADE
}

const IndexPage = () => (
  <AlertProvider template={AlertTemplate} {...options}>
    <Layout>
      <SEO title="Home" />
      <Container variant="flexContainer">
        <Styled.h1
          sx={{
            color: "primary",
            paddingTop: [30, 50, 70],
          }}
        >
          WE ARE <span sx={{ color: "accent" }}>VALE</span>
        </Styled.h1>
        <Styled.h1
          sx={{
            color: "primary",
          }}
        >
          <Typing hideCursor="true" speed={60} startDelay={50}>
            <span>AND WE LOVE</span>
            <Typing.Delay ms={400} />
            <Typing.Backspace count={4} />
            <span>ADORE</span>
            <Typing.Delay ms={400} />
            <Typing.Backspace count={5} />
            <span>FANCY</span>
            <Typing.Delay ms={400} />
            <Typing.Backspace count={5} />
            <span>
              <span sx={{ color: "secondary" }}>♥</span> MEDIA
            </span>
          </Typing>
        </Styled.h1>
        <FadeIn delay="200">
          <Styled.h3 sx={{ paddingTop: [20, 30, 30] }}>
            Movies & TV make our lives better (it’s science), but sometimes
            friction like authentication, search, and legacy business models
            make it harder to enjoy our content{" "}
            <HeartDislike
              sx={{
                display: "inline-block",
                paddingBottom: [1, 10, 0],
                verticalAlign: "middle",
                width: [35, 50, 60],
                height: "100%",
                fill: "secondary",
              }}
            />
          </Styled.h3>
          <Styled.h3>
            We build technologies that make our experience better{" "}
            <Wand
              sx={{
                display: "inline-block",
                paddingBottom: [1, 10, 0],
                verticalAlign: "middle",
                width: [35, 50, 60],
                height: "100%",
                fill: "secondary",
              }}
            />
          </Styled.h3>
          <Styled.h3>
            Keeping us focused on the shows that make our hearts beat{" "}
            <Pulse
              sx={{
                display: "inline-block",
                paddingBottom: [1, 10, 0],
                verticalAlign: "middle",
                width: [35, 50, 60],
                height: "100%",
                fill: "secondary",
              }}
            />
          </Styled.h3>
          <Styled.h3>
            We build a lot{" "}
            <Cog
              sx={{
                display: "inline-block",
                paddingBottom: [1, 10, 0],
                verticalAlign: "middle",
                width: [35, 50, 60],
                height: "100%",
                fill: "secondary",
              }}
            />
          </Styled.h3>
          <Styled.h3>
            But we also like to share{" "}
            <Mail
              sx={{
                display: "inline-block",
                paddingBottom: [1, 10, 0],
                verticalAlign: "middle",
                width: [35, 50, 60],
                height: "100%",
                fill: "secondary",
              }}
            />
          </Styled.h3>
          <SignupForm />
        </FadeIn>
      </Container>
    </Layout>
  </AlertProvider>
)

export default IndexPage;


