import React from 'react'
import axios from 'axios'
import { Input, Button, Flex, Styled, Box } from 'theme-ui'
import Check from "../assets/checkmark.inline.svg"
import Checkdone from "../assets/checkmarkdone.inline.svg"
import validator from 'email-validator'
import { withAlert } from 'react-alert'

class SignupForm extends React.Component {
    state = {
        email: null,
        emailIsValid: false,
        submitted: false,
    };

    validateEmail = () => {
      this.setState({ emailIsValid: validator.validate(this.state.email) })
    }

    updateValues = (e) => {
      console.log({
        [`${e.target.name}`]: e.target.value
      });
      this.setState({
        [`${e.target.name}`]: e.target.value,
      });
    };

    _handleChange = (e) => {
      console.log({
        [`${e.target.name}`]: e.target.value,
      });
      this.setState({
        [`${e.target.name}`]: e.target.value,
      });
    };

    _handleSubmit = e => {
      e.preventDefault();
      if (this.state.email === "" ) {
        this.props.alert.error("Cannot be blank");
      } else if (this.state.email === null) {
        this.props.alert.error("Cannot be blank")
      } else {
        const url = "https://script.google.com/macros/s/AKfycbxl8hVa3xknFhAyxrpGE6guqsPTfQ9_FB4h_8lsLv9cNdEK4Fw/exec"
        axios({
          method: "post",
          url: `${url}?email=${encodeURIComponent(
            this.state.email
          )}`,
        })
        this.setState({
          submitted: !this.state.submitted,
        })
        this.formRef.reset()
        this.props.alert.success("📬 Signed, Sealed, Delivered!")
        }
      }

    render() {
        const alert = this.props.alert;
        return (
          <form onSubmit={this._handleSubmit} ref={ref => (this.formRef = ref)}>
            <Styled.h2>Let's keep in touch</Styled.h2>
            <Flex sx={{ maxWidth: "500px" }}>
              <Box sx={{ flex: "1 1 auto" }}>
                <Input
                  sx={{
                    bg: "muted",
                    border: "none",
                    color: "#213765",
                    "::placeholder": {
                      color: "#213765",
                      opacity: 0.75,
                    },
                  }}
                  type="email"
                  onChange={this._handleChange}
                  placeholder="Email"
                  name="email"
                />
              </Box>
              <Box sx={{ marginLeft: 3 }}>
                <Button
                  variant="form"
                  sx={{
                    "&:hover": {
                      color: "background",
                      bg: "footer",
                    },
                  }}
                  disabled={this.state.submitted}
                >
                  {this.state.submitted ? <Checkdone /> : <Check />}
                </Button>
              </Box>
            </Flex>
          </form>
        )
    }
}

export default withAlert()(SignupForm)